// utils/index.js

import Vue from 'vue';

export const EventBus = new Vue();

export function isValidJwt(jwt) {
	if (!jwt || jwt.split('.').length < 3) {
		return false;
	}
	const data = JSON.parse(atob(jwt.split('.')[1]));
	const exp = new Date(data.exp * 1000); // JS deals with dates in milliseconds since epoch
	const now = new Date();
	return now < exp;
}

export function isAdminUser(jwt) {
	if (!jwt || jwt.split('.').length < 3) {
		return false;
	}
	const data = JSON.parse(atob(jwt.split('.')[1]));
	if (!data.is_admin) {
		return false;
	}
	return !!data.is_admin;
}

export function isChildUser(jwt) {
	if (!jwt || jwt.split('.').length < 3) {
		return false;
	}
	const data = JSON.parse(atob(jwt.split('.')[1]));
	if (!data.is_child) {
		return false;
	}
	return !!data.is_child;
}
export function getUsernameUser(jwt) {
	if (!jwt || jwt.split('.').length < 3) {
		return false;
	}
	const data = JSON.parse(atob(jwt.split('.')[1]));
	if (!data.username) {
		return false;
	}
	return data.username;
}
