<template>
	<section class="account">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<div v-if="loading" class="text-center">
			<v-progress-circular :size="100" color="yellow" indeterminate class="my-10"></v-progress-circular>
		</div>
		<v-container v-else>
			<v-row v-if="user">
				<v-col>
					<v-card class="border-shaped">
						<v-card-title class="text-h5 text-lg-h4">
							<strong>{{ $t('user.publishername') }}</strong> :
							{{ user.real_name }}
							<v-card-actions v-if="user !== null" class="ml-auto">
								<user-edit :user="user"></user-edit>
							</v-card-actions>
						</v-card-title>
						<v-card-text>
							<v-list three-line>
								<v-list-item>
									<v-list-item-icon>
										<v-icon class="text-lg-h2 text-h4">mdi-account-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>
											<strong>{{ $t('user.username') }}</strong> :
											{{ user.username }}
										</v-list-item-title>
										<v-list-item-subtitle
											><strong>{{ $t('global.email') }}</strong> : {{ user.email }}</v-list-item-subtitle
										>
										<v-list-item-subtitle
											><strong>{{ $t('global.phone') }}</strong> : {{ user.phone }}</v-list-item-subtitle
										>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="address">
									<v-list-item-icon>
										<v-icon class="text-lg-h2 text-h4">mdi-map-marker-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>
											<strong>{{ $t('address.billing') }}</strong>
											<v-btn
												small
												:to="{
													name: 'Address',
												}"
												depressed
												class="border-shaped yellow--bg ml-1"
											>
												{{ $t('form.edit') }}
											</v-btn>
										</v-list-item-title>
										<v-list-item-subtitle>
											<strong>{{ $t('address.full') }} </strong> :
											{{ address.address_full }}
											{{ address.country.name }}
										</v-list-item-subtitle>
										<v-list-item-subtitle>
											<div>
												<strong>{{ $t('address.contact') }}</strong> :
												{{ address.contact }}
											</div>
										</v-list-item-subtitle>
										<v-list-item-subtitle>
											<strong>{{ $t('address.raison_sociale') }}</strong
											>:
											<span v-if="address.raison_sociale"> {{ address.raison_sociale }}</span>
											<span v-else class="grey--text font-italic">
												{{ $t('address.no_raison_sociale') }}
											</span></v-list-item-subtitle
										>
										<v-list-item-subtitle
											><strong>{{ $t('address.address') }}</strong> : {{ address.address }}</v-list-item-subtitle
										>
										<v-list-item-subtitle v-if="address.complement"
											><strong>{{ $t('address.complement') }}</strong> : {{ address.complement }}</v-list-item-subtitle
										>
										<v-list-item-subtitle>
											<strong>{{ $t('address.postal_code') }}</strong
											>: {{ address.code_postal }}</v-list-item-subtitle
										>
										<v-list-item-subtitle>
											<strong>{{ $t('address.city') }}</strong> : {{ address.city }}</v-list-item-subtitle
										>
										<v-list-item-subtitle>
											<strong>{{ $t('address.country') }}</strong> :
											{{ address.country.name }}
										</v-list-item-subtitle>
										<v-list-item-subtitle v-if="address.phone_mobile">
											<strong>{{ $t('address.phone.mobile') }}</strong> : {{ address.phone_mobile }}</v-list-item-subtitle
										>
										<v-list-item-subtitle v-if="address.phone_fix">
											<strong>{{ $t('address.phone.fix') }}</strong> : {{ address.phone_fix }}</v-list-item-subtitle
										>
										<v-list-item-subtitle
											><strong>{{ $t('global.email') }}</strong> : {{ address.email }}</v-list-item-subtitle
										>
										<v-list-item-subtitle
											><strong>{{ $t('address.type') }}</strong> : {{ address.address_type.name }}</v-list-item-subtitle
										>
										<v-list-item-subtitle v-if="address.comment">
											<strong>{{ $t('address.comment') }}</strong> :
											<div>
												<i>{{ address.comment }}</i>
											</div>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-icon>
										<v-icon class="text-lg-h2 text-h4">mdi-information-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title
											><strong>{{ $t('global.info') }}</strong></v-list-item-title
										>
										<v-list-item-subtitle>
											<strong>{{ $t('global.created_at') }}</strong> :
											{{ new Date(new Date(user.created_at).toLocaleString('en-US') + ' UTC').toLocaleString() }}
										</v-list-item-subtitle>
										<v-list-item-subtitle>
											<strong>{{ $t('user.last_login') }}</strong> :
											{{
												user.last_login ? new Date(new Date(user.last_login).toLocaleString('en-US') + 'UTC').toLocaleString() : ''
											}}
										</v-list-item-subtitle>
										<v-list-item-subtitle>
											<strong>{{ $t('user.code_compta') }}</strong> :
											{{ user.code_compta }}
										</v-list-item-subtitle>
										<v-list-item-subtitle>
											<strong>{{ $t('user.code_masterprint') }}</strong> :
											{{ user.code_masterprint }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mb-2">
				<v-col>
					<v-form ref="formPassword" @submit.prevent="changePassword">
						<v-card class="border-shaped">
							<v-container>
								<v-row>
									<v-col cols="12" md="8" lg="6" class="mx-auto">
										<v-card-title class="text-h6 text-lg-h5"
											><strong>{{ $t('user.change_password') }}</strong></v-card-title
										>
										<v-card-text>
											<v-text-field
												v-model="old_password"
												:label="$t('form.current_password')"
												outlined
												class="border-shaped"
												type="password"
												:rules="password_rules"
											>
												<template #label></template>
											</v-text-field>
											<v-text-field
												v-model="password"
												:label="$t('form.new_password')"
												outlined
												class="border-shaped"
												type="password"
												:rules="password_rules"
											>
												<template #label></template>
											</v-text-field>
											<v-text-field
												v-model="confirm_password"
												:label="$t('form.confirm_password')"
												outlined
												class="border-shaped"
												type="password"
												:rules="confirm_password_rules"
											></v-text-field>
											<password v-model="password" :strength-meter-only="true" @feedback="showFeedback" />
											<v-list dense>
												<v-list-item v-for="(item, i) in feedback" :key="i">
													<v-list-item-icon>
														<v-icon>mdi-information</v-icon>
													</v-list-item-icon>
													{{ item }}
												</v-list-item>
											</v-list>
										</v-card-text>
										<v-card-actions>
											<v-btn large type="submit" class="border-shaped yellow--bg ml-auto" depressed :loading="loading_password">
												{{ $t('user.change_password') }}
											</v-btn>
										</v-card-actions>
									</v-col>
								</v-row>
							</v-container>
						</v-card>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="js">
	import PageTitle from '@/components/info/PageTitle';
	import axios from 'axios';
	import Password from 'vue-password-strength-meter';
	import { EventBus } from '@/utils';
	import UserEdit from '@/components/user/UserEdit';

	export default {
	    name: 'Account',
	    metaInfo: {
	        title: 'Rapidbook - Account',
	        metas: [
	            {
	                name: 'com.silverpop.brandeddomains',
	                content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
	            },
	        ],
	        script: [
	            {
	                src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
	            },
	        ],
	    },
	    components: { UserEdit, PageTitle, Password },
	    props: [],
	    data() {
	        return {
	            user: null,
	            address: null,
	            loading: true,
	            loading_password: false,
	            old_password: '',
	            password: '',
	            confirm_password: '',
	            feedback: [],
	            password_rules: [
	                (v) => v !== null || this.$t('form.required.password'),
	                (v) => v.length > 0 || this.$t('form.required.password'),
	            ],
	            confirm_password_rules: [
	                (v) => v !== null || this.$t('form.required.confirm'),
	                (v) => v.length > 0 || this.$t('form.required.confirm'),
	                () => this.password === this.confirm_password || this.$t('form.passwords_dont_match'),
	            ],
	        };
	    },
	    computed: {
	        items: function () {
	            return [
	                {
	                    text: 'Rapidbook',
	                    disabled: false,
	                    to: '/',
	                },
	                {
	                    text: 'Dashboard',
	                    disabled: false,
	                    to: 'dashboard',
	                },
	                {
	                    text: this.$t('menu.account'),
	                    disabled: true,
	                    to: '',
	                },
	            ];
	        },
	    },
	    mounted() {
	        this.getUser();
	        EventBus.$on('refreshUser', () => {
	            this.getUser();
	        });
	    },
	    methods: {
	        showFeedback({ suggestions }) {
	            this.feedback = suggestions;
	        },
	        getUser() {
	            axios
	                .get(process.env.VUE_APP_API_URL + '/user', {
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                })
	                .then((response) => {
	                    this.user = response.data.user;
	                    this.user.addresses.map((a) => {
	                        if (a.pinned) {
	                            this.address = a;
	                        }
	                    });
	                    this.loading = false;
	                });
	        },
	        changePassword(e) {
	            e.preventDefault();
	            this.$refs.formPassword.validate();
	            if (this.password === this.confirm_password) {
	                this.loading_password = true;
	                axios
	                    .patch(
	                        process.env.VUE_APP_API_URL + '/user',
	                        {
	                            old_password: this.old_password,
	                            password: this.password,
	                            confirm_password: this.confirm_password,
	                        },
	                        {
	                            headers: {
	                                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                            },
	                        }
	                    )
	                    .then(() => {
	                        EventBus.$emit('notif', this.$t('form.password_changed'), 'success');
	                        this.loading_password = false;
	                        this.$refs.formPassword.reset();
	                        this.password = '';
	                        this.old_password = '';
	                        this.confirm_password = '';
	                    })
	                    .catch((e) => {
	                        EventBus.$emit('notif', e.response.data.message, 'error');
	                        this.loading_password = false;
	                    });
	            }
	        },
	    },
	};
</script>

<i18n lang="json">
{
	"fr": {
		"title": "Mon Compte"
	},
	"en": {
		"title": "My Account"
	}
}
</i18n>
