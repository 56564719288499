<template>
	<div>
		<v-dialog v-model="product.dialog" persistent scrollable max-width="800px" transition="dialog-transition">
			<v-card v-if="!product.ready" shaped>
				<v-card-title class="text-h5"> {{ product.title || 'Produit' }} </v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-form ref="form" @submit.prevent="validateProduct" v-model="product.valid">
								<v-text-field
									v-model="product.title"
									:label="$t('product.title')"
									outlined
									class="border-shaped mt-2"
									:rules="titleRules"
								/>
								<v-text-field
									v-model="product.page_count"
									:label="$t('product.page_count')"
									type="number"
									outlined
									class="border-shaped"
									:rules="pageCountRules"
								/>
								<v-select
									v-model="product.category"
									:items="category_items"
									:rules="categoryRules"
									class="border-shaped"
									:label="$t('product.category')"
									:item-text="
										(item) =>
											$i18n.locale === 'fr' ? item.name : item.en_name + ' - ' + $t('order.price.tva') + ' : ' + item.tva + '%'
									"
									outlined
									required
									return-object
								>
									<template #selection="{ item }">
										<v-list-item-content>
											<v-list-item-title> {{ item.name }} - TVA : {{ item.tva }}%</v-list-item-title>
										</v-list-item-content>
									</template>
									<template #item="{ item }">
										<v-list-item-content>
											<v-list-item-title> {{ item.name }} - TVA : {{ item.tva }}%</v-list-item-title>
										</v-list-item-content>
									</template>
								</v-select>
								<v-select
									v-model="product.finition"
									:label="$t('product.finition')"
									outlined
									class="border-shaped"
									return-object
									:items="finition_items"
									:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
									required
									:rules="finitionRules"
								></v-select>
								<v-select
									v-model="product.impression"
									:label="$t('product.impression')"
									outlined
									class="border-shaped"
									return-object
									:items="impression_items_filtered"
									:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
									required
									:rules="impressionRules"
								></v-select>
								<div v-if="product.enableFormat" class="d-flex gap-2">
									<v-text-field v-model="product.width" label="Width" type="number" outlined class="border-shaped" />
									<v-text-field v-model="product.height" label="Height" type="number" outlined class="border-shaped" />
								</div>
								<div v-else>
									<v-select
										:items="format_items"
										v-model="product.format"
										:label="$t('product.format')"
										item-text="name"
										return-object
										outlined
										class="border-shaped"
									/>
								</div>
								<v-switch
									v-model="product.enableFormat"
									:label="$t('product.custom_format')"
									class="mt-n5"
									@change="updateFormat"
								></v-switch>
								<v-lazy v-if="product.enableCouverture" transition="slide-y-transition">
									<div>
										<v-select
											v-model="product.papier_couverture"
											:label="$t('product.cover_paper')"
											:item-text="(item) => ($i18n.locale === 'fr' ? item.papier.name : item.papier.en_name)"
											outlined
											return-object
											:rules="papierCouvertureRules"
											required
											class="border-shaped"
											:items="papier_couverture_items"
										></v-select>
										<v-select
											v-model="product.pelliculage"
											:label="$t('product.lamination')"
											outlined
											class="border-shaped"
											return-object
											:items="pelliculage_items"
											:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
											:rules="pelliculageRules"
											:disabled="product.papier_couverture?.papier.is_creation"
											:hint="product.papier_couverture?.papier.is_creation ? $t('papier_creation') : ''"
											persistent-hint
										></v-select>
									</div>
								</v-lazy>
								<v-switch
									v-if="!!product.impression ? product.impression.id === 2 : false"
									v-model="product.recto_verso"
									:label="$t('product.recto_verso')"
									class="mt-n3"
								></v-switch>
								<v-select
									v-model="product.papier_interieur"
									:label="$t('product.inner_paper')"
									outlined
									class="border-shaped"
									return-object
									:rules="papierInterieurRules"
									required
									:items="papier_interieur_items"
									:item-text="(item) => ($i18n.locale === 'fr' ? item.papier.name : item.papier.en_name)"
								></v-select>
								<v-lazy v-if="product.finition?.id !== 2" transition="slide-y-transition">
									<v-switch v-model="product.bords_perdus" :label="$t('product.lost_edges')" class="mt-n3"></v-switch>
								</v-lazy>
								<v-lazy v-if="product.finition?.id === 2" transition="slide-y-transition">
									<v-switch v-model="product.enableCouverture" :label="$t('enable_couverture')" class="mt-n3"></v-switch>
								</v-lazy>
							</v-form>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn depressed class="yellow--bg border-shaped" @click="validateProduct" large>
						{{ $t('validate') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<div class="transition-fast-in-fast-out v-card--reveal">
			<v-card-text class="text-caption">
				<div>{{ $t('product.title') }} : {{ product.title }}</div>
				<div>{{ $t('product.page_count') }} : {{ product.page_count }}</div>
				<div>{{ $t('product.finition') }} : {{ product.finition?.name }}</div>
				<div>{{ $t('product.impression') }} : {{ product.impression?.name }}</div>
				<div>{{ $t('product.format') }} : {{ product.format?.name }}</div>
				<div>{{ $t('product.category') }} : {{ product.category?.name }}</div>
				<div>{{ $t('product.inner_paper') }} : {{ product.papier_interieur?.papier.name }}</div>
				<div>{{ $t('product.cover_paper') }} : {{ product.papier_couverture?.papier.name }}</div>
				<div>{{ $t('product.lamination') }} : {{ product.pelliculage?.name }}</div>
				<div>{{ $t('product.recto_verso') }} : {{ product.recto_verso ? 'Oui' : 'Non' }}</div>
				<div>{{ $t('product.lost_edges') }} : {{ product.bords_perdus ? 'Oui' : 'Non' }}</div>
			</v-card-text>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import { EventBus } from '@/utils';

	export default {
		name: 'ProductForm',
		props: {
			product: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				category_items: [],
				finition_items: [],
				impression_items: [],
				format_items: [],
				papier_couverture_items_full: [],
				papier_interieur_items_full: [],
				pelliculage_items: [],
				countries: [],
				address_types_items: [],
				titleRules: [(v) => v !== null || this.$t('form.required.product.title')],
				finitionRules: [(v) => v !== null || this.$t('form.required.product.finition')],
				categoryRules: [(v) => v !== null || this.$t('form.required.product.category')],
				address_typesRules: [(v) => v !== null || this.$t('form.required.address.type')],
				impressionRules: [(v) => v !== null || this.$t('form.required.product.impression')],
				formatRules: [
					() => (!this.product.enableFormat && this.product.format !== null) || this.$t('form.required.product.format'),
				],
				widthRules: [
					() => {
						if (!this.product.enableFormat) {
							return true;
						}
						if (!this.product.width) {
							return this.$t('form.required.product.width');
						}

						if (!isNumeric('' + this.product.width || '')) {
							return this.$t('form.required.product.width');
						}

						if (
							parseFloat(this.product.width) < parseFloat(this.product.finition?.min_width_interieur) ||
							parseFloat(this.product.width) > parseFloat(this.product.finition?.max_width_interieur)
						)
							return this.$t('width_limit', [
								parseFloat(this.product.finition?.min_width_interieur),
								parseFloat(this.product.finition?.max_width_interieur),
							]);

						return true;
					},
				],
				heightRules: [
					() => {
						if (!this.product.enableFormat) {
							return true;
						}

						if (!this.product.height) {
							return this.$t('form.required.product.height');
						}

						if (!isNumeric('' + this.product.height || '')) {
							return this.$t('form.required.product.height');
						}

						if (
							parseFloat(this.product.height) < parseFloat(this.product.finition?.min_height_interieur) ||
							parseFloat(this.product.height) > parseFloat(this.product.finition?.max_height_interieur)
						)
							return this.$t('height_limit', [
								parseFloat(this.product.finition?.min_height_interieur),
								parseFloat(this.product.finition?.max_height_interieur),
							]);

						return true;
					},
				],
				papierCouvertureRules: [
					(v) => {
						if (![null, undefined].includes(v)) {
							return (
								v.papier.impression === null ||
								v.papier.impression.id === this.product.impression.id ||
								this.$t('form.invalid.product.paper.cover')
							);
						}
						return this.$t('form.required.product.paper.cover');
					},
				],
				pelliculageRules: [(v) => v !== null || this.$t('form.required.product.lamination')],
				rectoVersoRules: [],
				papierInterieurRules: [
					(v) => {
						if (![null, undefined].includes(v)) {
							return (
								v.papier.impression === null ||
								v.papier.impression.id === this.product.impression.id ||
								this.$t('form.invalid.product.paper.inner')
							);
						}
						return this.$t('form.required.product.paper.inner');
					},
				],
				pageCountRules: [
					() => {
						// TODO: adapter les verifications en fonction des valeurs en bdd
						if (this.product.finition?.id === 2) {
							if (this.product.page_count < 8) {
								return this.$t('page_count_min_pique');
							}
							if (this.product.page_count > 76) {
								return this.$t('page_count_max_pique');
							}
							if (this.product.page_count % 4 !== 0) {
								return this.$t('page_count_by_4');
							}
						} else {
							if (this.product.page_count < 0) {
								return this.$t('page_count_min');
							}
							if (this.product.page_count % 2 !== 0) {
								return this.$t('page_count_odd');
							}
						}
						return true;
					},
				],
				bordsPerdusRules: [],
			};
		},

		mounted() {
			this.getCategory();
			this.getFinitions();
			this.getFormats();
			this.getImpressions();
			this.getPapierCouverture();
			this.getPapierInterieur();
			this.getPelliculage();
		},
		methods: {
			validateProduct() {
				if (this.$refs.form.validate()) {
					this.product.dialog = false;
				}
			},
			setPapierCouverture(papier) {
				this.product.papier_couverture = papier;
			},
			setPapierInterieur(papier) {
				this.product.papier_interieur = papier;
			},
			getFinitions() {
				axios
					.get(process.env.VUE_APP_API_URL + '/finition', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.finition_items = r.data.finitions;
						!this.product.finition ? (this.product.finition = this.finition_items[0]) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			getCategory() {
				axios
					.get(process.env.VUE_APP_API_URL + '/category', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.category_items = r.data.categories;
						this.product.category = this.category_items[0];
					})
					.catch(() => {});
			},
			getImpressions() {
				axios
					.get(process.env.VUE_APP_API_URL + '/impression', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.impression_items = r.data.impressions;
						if (this.product.impression === null) {
							this.product.impression = this.impression_items[0];
						}
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			getCountries() {
				axios
					.get(process.env.VUE_APP_API_URL + '/country', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.countries = response.data.countries;
						this.product.country = this.countries[0];
					});
			},
			getAddressType() {
				axios
					.get(process.env.VUE_APP_API_URL + '/address_type', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.address_types_items = r.data.address_types;
						!this.product.address_type ? (this.product.address_type = this.address_types_items[0]) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			updateImpression() {
				if (this.product.impression === null) {
					this.papier_couverture_items = [];
					this.product.papier_couverture = { id: 0, name: this.$t('choose_impression') };

					this.papier_interieur_items = [];
					this.product.papier_interieur = { id: 0, name: this.$t('choose_impression') };
					return false;
				}

				this.papier_couverture_items = this.papier_couverture_items_full.filter((p) => {
					return p.papier.impression === null || p.papier.impression.id === this.product.impression.id;
				});
				this.papier_interieur_items = this.papier_interieur_items_full.filter((p) => {
					return p.papier.impression === null || p.papier.impression.id === this.product.impression.id;
				});

				!this.product.papier_couverture ? (this.product.papier_couverture = this.papier_couverture_items[0]) : null;
				!this.product.papier_interieur ? (this.product.papier_interieur = this.papier_interieur_items[0]) : null;
			},
			getFormats() {
				axios
					.get(process.env.VUE_APP_API_URL + '/format', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.format_items = r.data.formats;
						!this.product.format ? (this.product.format = this.format_items[0]) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			updateFormat() {
				if (this.enableFormat) {
					this.product.format = null;
				} else {
					this.product.width = null;
					this.product.height = null;
				}
			},
			getPelliculage() {
				axios
					.get(process.env.VUE_APP_API_URL + '/pelliculage', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.pelliculage_items = r.data.pelliculages;
						!this.product.pelliculage ? (this.product.pelliculage = this.pelliculage_items[0]) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			getPapierCouverture() {
				axios
					.get(process.env.VUE_APP_API_URL + '/papier_couverture', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.papier_couverture_items_full = r.data.papiers_couverture;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			getPapierInterieur() {
				axios
					.get(process.env.VUE_APP_API_URL + '/papier_interieur', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.papier_interieur_items_full = r.data.papiers_interieur;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
		},
		computed: {
			impression_items_filtered() {
				return this.impression_items.filter((i) => {
					return (this.product.finition?.id === 2 && i.id === 2) || this.product.finition?.id === 1;
				});
			},
			papier_couverture_items: function () {
				if (this.product.impression === null) {
					this.product.papier_couverture ? this.setPapierCouverture({ id: 0, name: this.$t('choose_impression') }) : null;
					return [];
				}

				let p = this.papier_couverture_items_full.filter((p) => {
					return p.papier.impression === null || p.papier.impression.id === this.product.impression.id;
				});

				!this.product.papier_couverture ? this.setPapierCouverture(p[0]) : null;

				return p;
			},
			papier_interieur_items: function () {
				if (this.product.impression === null) {
					this.product.papier_interieur ? this.setPapierInterieur({ id: 0, name: this.$t('choose_impression') }) : null;
					return [];
				}

				let p = this.papier_interieur_items_full.filter((p) => {
					return p.papier.impression === null || p.papier.impression.id === this.product.impression.id;
				});

				!this.product.papier_interieur ? this.setPapierInterieur(p[0]) : null;

				return p;
			},
		},
		watch: {
			'product.finition': function () {
				if (this.product.finition?.id === 2) {
					this.product.impression = this.impression_items_filtered[0];
					this.product.bords_perdus = true;
				} else {
					this.product.enableCouverture = true;
				}
			},
			'product.papier_couverture': function () {
				if (this.product.papier_couverture?.papier.is_creation) {
					this.product.pelliculage = this.pelliculage_items[this.pelliculage_items.length - 1];
				}
			},
			'product.enableFormat': function () {
				if (this.product.enableFormat) {
					this.product.format = null;
				}
			},
			'product.enableCouverture': function () {
				if (this.product.enableCouverture) {
					this.product.papier_couverture = this.papier_couverture_items[0];
				} else {
					this.product.papier_couverture = null;
					this.product.pelliculage = this.pelliculage_items[this.pelliculage_items.length - 1];
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>

<i18n lang="json">
{
	"fr": {
		"title": "Simulation Tarifaire",
		"unavailable": "Non disponible",
		"go_to_creation": "Passer à la création de produit",
		"choose_impression": "Choisissez un mode d'impression",
		"quantity_min": "Quantité supérieure à {0}",
		"quantity_min_pique": "Quantité minimum supérieure à 50",
		"quantity_max": "Quantité inférieure ou égal à 2000",
		"page_count_min": "Nombre de pages supérieur à 0",
		"page_count_min_pique": "Nombre de pages supérieur à 8",
		"page_count_max_pique": "Nombre de pages inférieure à 76 (76 + 4 (couverture))",
		"page_count_odd": "Nombre de pages positif ou divisible par 2",
		"page_count_by_4": "Nombre de pages positif ou divisible par 4",
		"calc_price": "Simuler",
		"width_limit": "La largeur doit être comprise entre {0} et {1} mm",
		"height_limit": "La hauteur doit être comprise entre {0} et {1} mm",
		"enable_couverture": "Activer/Désactiver la couverture",
		"papier_creation": "Pas de pelliculage pour un papier de création",
		"validate": "Valider ce produit"
	},
	"en": {
		"title": "Pricing Simulation",
		"unavailable": "Unavailable",
		"go_to_creation": "Go to product create form",
		"choose_impression": "Choose a printing mode",
		"quantity_min": "Quantity above {0}",
		"quantity_min_pique": "Quantity above 50",
		"quantity_max": "Quantity equal or less than 2000",
		"page_count_min": "Number of pages above 0",
		"page_count_min_pique": "Number of pages above 8",
		"page_count_max_pique": "Number of pages below 76 (76 + 4 (cover))",
		"page_count_odd": "Number of pages positive or divisible by 2",
		"page_count_by_4": "Number of pages positive or divisible by 4",
		"calc_price": "Simulate",
		"width_limit": "The width must be between {0} and {1} mm",
		"height_limit": "The height must be between {0} and {1} mm",
		"enable_couverture": "Enable/Disable cover",
		"papier_creation": "No pelliculage for a creation paper",
		"validate": "Validate this product"
	}
}
</i18n>
