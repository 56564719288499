<template>
	<section class="adresses">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<v-container>
			<address-add></address-add>
			<address-list></address-list>
		</v-container>
	</section>
</template>

<script lang="js">
	import AddressList from '@/components/address/AddressList';
	import AddressAdd from '@/components/address/AddressAdd';
	import PageTitle from '@/components/info/PageTitle';

	export default {
		name: 'Address',
		metaInfo: {
			title: 'Rapidbook - Address',
			metas: [
				{
					name: 'com.silverpop.brandeddomains',
					content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
				},
			],
			script: [
				{
					src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
				},
			],
		},
		components: { PageTitle, AddressAdd, AddressList },
		props: [],
		data() {
			return {};
		},
		computed: {
			items: function () {
				return [
					{
						text: 'Rapidbook',
						disabled: false,
						to: '/',
					},
					{
						text: 'Dashboard',
						disabled: false,
						to: 'dashboard',
					},
					{
						text: this.$t('address.address'),
						disabled: true,
						to: '',
					},
				];
			},
		},
		mounted() { },
		methods: {},
	};
</script>

<style scoped lang="scss">
	.adresses {
		min-height: calc(100vh - 64px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Mes Adresses"
	},
	"en": {
		"title": "My Addresses"
	}
}
</i18n>
