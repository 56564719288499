<template>
	<div>
		<v-btn small class="border-shaped ml-2 mb-2 yellow--bg" depressed @click="toggleDialog">
			<v-icon>mdi-pencil</v-icon>
		</v-btn>
		<v-dialog v-model="dialog" max-width="900px">
			<v-form ref="formEditTarif" v-model="valid" @submit.prevent="editTarif">
				<v-card shaped elevation="24">
					<v-card-title class="text-h4 text-lg-h3 font-weight-bold"> Editer Tarif </v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-text-field
							v-model="tranche"
							label="Tranche"
							type="number"
							step="0.01"
							outlined
							clearable
							class="border-shaped"
						></v-text-field>
						<v-text-field
							v-model="nb_colis"
							label="Nombre de colis"
							type="number"
							outlined
							clearable
							class="border-shaped"
						></v-text-field>
						<v-text-field
							v-model="adresse_pro"
							label="Adresse Pro"
							type="number"
							step="0.01"
							outlined
							clearable
							class="border-shaped"
						></v-text-field>
						<v-text-field
							v-model="adresse_particulier"
							label="Adresse Particulier"
							type="number"
							step="0.01"
							outlined
							clearable
							class="border-shaped"
						></v-text-field>
						<v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
					</v-card-text>
					<v-card-actions>
						<v-btn class="border-shaped yellow--bg ml-auto mb-3" type="submit" :loading="loading" elevation="0">
							Valider
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import { EventBus } from '@/utils';
	export default {
		props: ['item'],
		data() {
			return {
				dialog: false,
				valid: false,
				loading: false,
				tranche: this.item.tranche,
				nb_colis: this.item.nb_colis,
				adresse_pro: this.item.adresse_pro,
				adresse_particulier: this.item.adresse_particulier,
				enabled: this.item.enabled,
			};
		},
		methods: {
			toggleDialog() {
				this.dialog = !this.dialog;
			},
			editTarif() {
				this.$refs.formEditTarif.validate();
				if (this.valid) {
					this.loading = true;
					const data = {
						tranche: this.tranche,
						nb_colis: this.nb_colis,
						adresse_pro: this.adresse_pro,
						adresse_particulier: this.adresse_particulier,
						enabled: this.enabled,
					};
					axios
						.put(process.env.VUE_APP_API_URL + '/admin/tarifslivraison/' + this.item.id, data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							EventBus.$emit('refreshtarifslivraison');
							this.dialog = false;
							this.loading = false;
							EventBus.$emit('notif', 'Tarif modifié', 'success');
						})
						.catch(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped></style>
