<template>
	<div>
		<v-btn class="border-shaped ml-2 yellow--bg" depressed @click="toggleDialog">
			<v-icon>mdi-pencil</v-icon>
		</v-btn>
		<v-dialog v-model="dialog" max-width="900px">
			<v-form ref="formEditEtat" v-model="valid" @submit.prevent="editEtat">
				<v-card shaped elevation="24">
					<v-card-title class="text-h4 text-lg-h3 font-weight-bold"> Editer Etat </v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-text-field v-model="name" label="Libellé" outlined clearable class="border-shaped"></v-text-field>
						<v-text-field v-model="en_name" label="Libellé Anglais" outlined clearable class="border-shaped"></v-text-field>
						<v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
					</v-card-text>
					<v-card-actions>
						<v-btn class="border-shaped yellow--bg ml-auto" type="submit" :loading="loading" depressed large> Valider </v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import { EventBus } from '@/utils';
	export default {
		props: ['item', 'typeEtat'],
		data() {
			return {
				dialog: false,
				valid: false,
				loading: false,
				name: this.item.name,
				en_name: this.item.en_name,
				enabled: this.item.enabled,
			};
		},
		methods: {
			toggleDialog() {
				this.dialog = !this.dialog;
			},
			editEtat() {
				this.$refs.formEditEtat.validate();
				if (this.valid) {
					this.loading = true;
					const data = {
						name: this.name,
						en_name: this.en_name,
						enabled: this.enabled,
					};
					axios
						.put(process.env.VUE_APP_API_URL + `/admin/${this.typeEtat}/${this.item.id}`, data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							switch (this.typeEtat) {
								case 'etats':
									EventBus.$emit('refreshetats');
									break;
								case 'etatscommande':
									EventBus.$emit('refreshetatscommande');
									break;
								case 'etatslignecommande':
									EventBus.$emit('refreshetatslignecommande');
									break;
								default:
									break;
							}
							this.dialog = false;
							this.loading = false;
							EventBus.$emit('notif', 'Etat modifiée', 'success');
						})
						.catch(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped></style>
