<template>
	<section class="library">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<v-container>
			<v-row>
				<product-grid></product-grid>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="js">
	import PageTitle from '@/components/info/PageTitle';
	import ProductGrid from '@/components/library/ProductGrid';

	export default {
	    name: 'Library',
	    metaInfo: {
	        title: 'Rapidbook - Library',
	        metas: [
	            {
	                name: 'com.silverpop.brandeddomains',
	                content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
	            },
	        ],
	        script: [
	            {
	                src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
	            },
	        ],
	    },
	    components: {
	        ProductGrid,
	        PageTitle,
	    },
	    data() {
	        return {};
	    },
	    computed: {
	        items: function () {
	            return [
	                {
	                    text: 'Rapidbook',
	                    disabled: false,
	                    to: '/',
	                },
	                {
	                    text: 'Dashboard',
	                    disabled: false,
	                    to: 'dashboard',
	                },
	                {
	                    text: this.$t('menu.library'),
	                    disabled: true,
	                    to: '',
	                },
	            ];
	        },
	    },
	};
</script>

<style scoped lang="scss">
	.library {
		min-height: calc(100vh - 64px);
	}

	.book {
		min-height: 50vh;

		.book-card {
			min-height: 100%;
		}

		.book-text {
			position: absolute;
			bottom: 0;
			background: var(--color-yellow);
			opacity: 0.85;
			height: 25%;
			transition: height 0.3s, opacity 0.3s;
		}

		.book-text-hover {
			height: 100%;
			opacity: 0.99;
			cursor: pointer;
		}

		.book-state {
			position: absolute;
			left: 5px;
			top: 5px;
			transition: all 0.3s;
		}

		.book-state-hover {
			left: 75%;
		}
	}

	.img-detail {
		height: 150px;
		transition: height 0.3s;
	}

	.img-detail-hover {
		height: 30em;
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Ma Bibliothèque"
	},
	"en": {
		"title": "My Library"
	}
}
</i18n>
