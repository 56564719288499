<template>
	<section class="items-admin">
		<v-container fluid>
			<v-row>
				<v-col>
					<v-card-title>
						<div v-if="itemType === 'products'">Produits</div>
						<v-switch
							v-if="itemType === 'products'"
							v-model="archive"
							label="Inclure les produits archivés"
							class="ml-2 mb-n1"
						></v-switch>
						<div v-if="itemType === 'orders'">Commandes</div>
						<div v-if="itemType === 'lines'">Lignes Commande</div>
						<div v-if="itemType === 'addresses'">Adresses</div>
						<v-spacer></v-spacer>
						<v-text-field
							v-model="search"
							class="border-shaped"
							append-icon="mdi-magnify"
							label="Rechercher"
							single-line
							hide-details
							outlined
						></v-text-field>
						<v-btn depressed @click="getItems(false)" class="border-shaped yellow--bg ml-2" x-large :loading="loading">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-title v-if="itemType === 'orders'">
						<v-spacer></v-spacer>
						<v-btn depressed @click="getItems(true)" class="border-shaped yellow--bg" x-large :loading="loading">
							<v-icon>mdi-download</v-icon>
						</v-btn>
					</v-card-title>
					<v-data-table
						:headers="headers"
						:items="items"
						:options.sync="options"
						:server-items-length="totalItems"
						:footer-props="footerProps"
						class="elevation-1 border-shaped"
						:loading="loading"
						dense
						style="white-space: nowrap"
						:height="screenHeight"
						fixed-header
					>
						<template #[`item.enabled`]="{ item }">
							<div
								class="d-flex align-center justify-center fill-height"
								:style="item.enabled ? 'background-color: #4caf50' : 'background-color: #f44336'"
							>
								<v-switch v-model="item.enabled" class="mb-n4 mt-1" color="white" @click="toggleItem(item.id)" flat></v-switch>
							</div>
						</template>
						<template #[`item.weight`]="{ item }">{{ Math.round(parseFloat(item.weight)) }} g </template>
						<template #[`item.epaisseur`]="{ item }">{{ Math.round(item.epaisseur * 2) / 2 }} mm </template>
						<template #[`item.action`]="{ item }">
							<div>
								<div v-if="itemType === 'products'" class="py-1 d-flex">
									<ProductsAdminDetail :product="item"></ProductsAdminDetail>
									<ProductAdminEdit :product="item" class="ml-1"></ProductAdminEdit>
									<v-btn depressed color="red" class="border-shaped ml-1" @click="deleteProduct(item.id)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
								<div v-if="itemType === 'orders'" class="py-1 d-flex">
									<v-lazy>
										<OrdersAdminDetail :id="item.id"></OrdersAdminDetail>
									</v-lazy>
									<v-btn color="red" class="border-shaped ml-1" depressed @click="deleteOrder(item.id)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
								<div v-if="itemType === 'addresses'">
									<AddressAdminEdit :item="item"></AddressAdminEdit>
								</div>
								<div v-if="itemType === 'lines'">
									<LinesOrderAdminEdit :line="item"></LinesOrderAdminEdit>
								</div>
							</div>
						</template>
						<template v-if="itemType === 'orders'" #[`item.prix_fabrication`]="{ item }"
							>{{ parseFloat(item.prix_fabrication).toFixed(2) }} €
						</template>
						<template v-if="itemType === 'orders'" #[`item.prix_livraison`]="{ item }"
							>{{ parseFloat(item.prix_livraison).toFixed(2) }} €
						</template>
						<template v-if="itemType === 'orders'" #[`item.prix_emballage`]="{ item }"
							>{{ parseFloat(item.prix_emballage).toFixed(2) }} €
						</template>
						<template v-if="itemType === 'orders'" #[`item.prix_ttc`]="{ item }"
							>{{ parseFloat(item.prix_ttc).toFixed(2) }} €
						</template>
						<template v-if="itemType === 'orders'" #[`item.prix_ht`]="{ item }"
							>{{ parseFloat(item.prix_ht).toFixed(2) }} €
						</template>
						<template #[`item.lignes_commande`]="{ item }">
							<div v-if="itemType === 'orders'">
								<span>{{ item.lignes_commande.length }}</span>
							</div>
							<div v-if="itemType === 'lines'">
								<LinesOrderAdminEdit :line="item"></LinesOrderAdminEdit>
							</div>
						</template>
						<template v-if="itemType === 'lines'" #[`item.product`]="{ item }">
							<v-list style="background: transparent">
								<v-list-item>
									<v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" rounded size="100">
										<VignetteFetcher :id="item.product.id" />
									</v-list-item-avatar>
									<v-list-item-content class="text-left">
										<v-list-item-title>{{ item.product.title }}</v-list-item-title>
										<v-list-item-subtitle>{{ item.product.page_count }} pages</v-list-item-subtitle>
										<v-list-item-subtitle>
											{{ item.address.address_full }}
											{{ item.address.country.name }}
										</v-list-item-subtitle>
										<v-list-item-subtitle> <strong>Contact</strong> : {{ item.address.contact }} </v-list-item-subtitle>
										<v-list-item-subtitle v-if="item.address.raison_sociale">
											<strong>Raison Sociale</strong> :
											{{ item.address.raison_sociale }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</template>
						<template v-if="itemType === 'addresses'" #[`item.comment`]="{ item }">
							<div style="max-height: 200px; max-width: 200px; white-space: normal" class="overflow-y-auto">
								{{ item.comment }}
							</div>
						</template>
						<template v-if="itemType === 'products'" #[`item.title`]="{ item }">
							<div style="max-height: 200px; max-width: 300px; min-width: 300px; white-space: normal" class="overflow-y-auto">
								{{ item.title }}
							</div>
						</template>
						<template v-if="itemType === 'products'" #[`item.f2`]="{ item }">
							{{ item.f2 === 1 ? 'Oui' : 'Non' }}
						</template>
						<template #[`item.created_at`]="{ item }">
							{{ new Date(new Date(item.created_at).toLocaleString('en-US') + ' UTC').toLocaleString() }}
						</template>
						<template #[`item.updated_at`]="{ item }">
							{{ new Date(new Date(item.updated_at).toLocaleString('en-US') + ' UTC').toLocaleString() }}
						</template>
						<template #[`item.datetime`]="{ item }">
							{{ new Date(new Date(item.datetime).toLocaleString('en-US') + ' UTC').toLocaleString() }}
						</template>
						<template #[`item.used`]="{ item }">
							{{ item.used ? 'Oui' : 'Non' }}
						</template>
						<template #[`item.method`]="{ item }">
							<span class="green--text" v-if="item.method == 'GET'">{{ item.method }}</span>
							<span class="orange--text" v-if="item.method == 'POST'">{{ item.method }}</span>
							<span class="purple--text" v-if="item.method == 'PUT'">{{ item.method }}</span>
							<span class="blue--text" v-if="item.method == 'PATCH'">{{ item.method }}</span>
							<span class="red--text" v-if="item.method == 'DELETE'">{{ item.method }}</span>
						</template>
						<template #[`item.user`]="{ item }">
							<span v-if="item.user">{{ item.user.username }}</span>
							<span v-else class="font-italic grey--text">Aucun</span>
						</template>
						<template #[`item.data`]="{ item }">
							<v-expansion-panels class="max-v-list-height">
								<v-expansion-panel>
									<v-expansion-panel-header>
										<strong>DONNÉES</strong>
									</v-expansion-panel-header>
									<v-expansion-panel-content v-for="(value, key) in item.data" :key="key">
										<span v-if="!['format', 'prices'].includes(key)">
											<strong>- {{ key }}</strong> :
											{{ value ? value : 'null' }}
										</span>
										<span v-else>
											<v-expansion-panels>
												<v-expansion-panel>
													<v-expansion-panel-header>
														<strong>{{ key }}</strong>
													</v-expansion-panel-header>
													<v-expansion-panel-content v-for="(v, k) in value" :key="k">
														<span>
															<strong>- {{ k }}</strong> : {{ v }}
														</span>
														<span v-if="!['format', 'prices'].includes(key)"></span>
													</v-expansion-panel-content>
												</v-expansion-panel> </v-expansion-panels
										></span>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="js">
	import axios from 'axios';
	import { EventBus } from '@/utils';

	import ProductsAdminDetail from '@/components/admin/products/ProductsAdminDetail';
	import ProductAdminEdit from '@/components/admin/products/ProductAdminEdit';
	import OrdersAdminDetail from '@/components/admin/orders/OrdersAdminDetail';
	import LinesOrderAdminEdit from '@/components/admin/lignescommande/LinesOrderAdminEdit';
	import AddressAdminEdit from '@/components/admin/addresses/AddressAdminEdit';
	import VignetteFetcher from '@/components/order/VignetteFetcher';

	export default {
		components: {
			ProductAdminEdit,
			ProductsAdminDetail,
			OrdersAdminDetail,
			LinesOrderAdminEdit,
			AddressAdminEdit,
			VignetteFetcher,
		},
		props: ['itemType', 'perPage', 'sortBy', 'headers'],
		data() {
			return {
				screenHeight: window.innerHeight - 250,
				apiUrl: process.env.VUE_APP_API_URL,
				search: '',
				intervalSearch: null,
				loading: true,
				options: {
					sortBy: [this.sortBy || 'updated_at'],
					sortDesc: [true],
					itemsPerPage: this.perPage || 30,
					page: 1,
				},
				archive: false,
				items: [],
				totalItems: 0,
				footerProps: {
					'items-per-page-options': [5, 10, 15, 30, 50, 100, 200, 300, 500, 1000, 5000, 10000],
					itemsPerPageText: 'Lignes par page',
				},
			};
		},
		watch: {
			options: {
				handler() {
					this.getItems();
				},
				deep: true,
			},
			archive() {
				this.getItems();
			},
			search() {
				clearTimeout(this.intervalSearch);
				this.intervalSearch = setTimeout(() => {
					this.getItems();
				}, 750);
			},
		},
		mounted() {
			EventBus.$on(`refresh${this.itemType}`, () => {
				this.getItems();
			});
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			});
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.onResize);
		},
		methods: {
			onResize() {
				this.screenHeight = window.innerHeight - (this.$vuetify.breakpoint.lgAndUp ? 250 : 300);
			},
			getItems(export_csv = false) {
				this.loading = true;
				let params = {
					sortBy: this.options.sortBy[0],
					sortDesc: this.options.sortDesc[0],
					itemsPerPage: this.options.itemsPerPage,
					search: this.search,
					archive: this.archive,
					page: this.options.page,
				};
				if (export_csv) {
					params.export_csv = export_csv;
				}
				axios
					.get(process.env.VUE_APP_API_URL + `/admin/${this.itemType}`, {
						params: params,
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						if (export_csv) {
							const BOM = '\uFEFF';
							const blob = new Blob([BOM + response.data], { type: 'text/csv;charset=utf-8;' });
							const url = window.URL.createObjectURL(blob);
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', 'export.csv'); // or any other extension
							document.body.appendChild(link);
							link.click();
						} else {
							this.totalItems = response.data.total;
							this.items = response.data.items;
						}
					})
					.catch(() => {
						EventBus.$emit('notif', "Une erreur s'est produite", 'error');
					})
					.finally(() => {
						this.loading = false;
					});
			},
			toggleItem(id) {
				axios
					.patch(process.env.VUE_APP_API_URL + `/admin/${this.itemType}/` + id, null, {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then(() => {
						switch (this.itemType) {
							case 'products':
								EventBus.$emit('notif', 'Produit modifié', 'success');
								break;
							case 'orders':
								EventBus.$emit('notif', 'Commande modifiée', 'success');
								break;
							case 'lines':
								EventBus.$emit('notif', 'Ligne modifiée', 'success');
								break;
							case 'addresses':
								EventBus.$emit('notif', 'Adresse modifiée', 'success');
								break;
							default:
								break;
						}
					})
					.catch(() => {
						EventBus.$emit('notif', "Une erreur s'est produite", 'error');
						this.getItems();
					});
			},
			deleteProduct(id) {
				if (confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
					axios
						.delete(process.env.VUE_APP_API_URL + '/admin/products/' + id, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							EventBus.$emit('notif', 'Produit supprimé', 'success');
							this.getItems();
						})
						.catch(() => {
							EventBus.$emit('notif', "Une erreur s'est produite", 'error');
							this.getItems();
						});
				}
			},
			deleteOrder(id) {
				if (confirm('Êtes-vous sûr de vouloir supprimer cette commande ?')) {
					axios
						.delete(process.env.VUE_APP_API_URL + '/admin/orders/' + id, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							EventBus.$emit('refreshPanier');
							EventBus.$emit('refreshorders');
						})
						.catch(() => {
							EventBus.$emit('refreshPanier');
							EventBus.$emit('refreshorders');
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.max-v-list-height {
		max-height: 400px;
		overflow-y: auto;
	}
</style>
