<template>
	<section class="login">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<v-form ref="form" v-model="valid" lazy-validation @submit="login">
			<v-container>
				<v-row>
					<v-col cols="12" sm="4" xl="3" class="mx-auto mt-16">
						<v-alert v-if="errorMsg" type="error">
							{{ errorMsg }}
						</v-alert>
						<v-text-field
							v-model="username"
							name="username"
							class="border-shaped"
							:rules="usernameRules"
							type="text"
							:label="$t('user.username')"
							prepend-inner-icon="mdi-account-outline"
							outlined
							autofocus
						></v-text-field>
						<v-text-field
							v-model="password"
							name="password"
							class="border-shaped"
							:rules="passwordRules"
							type="password"
							:label="$t('form.password')"
							prepend-inner-icon="mdi-lock-outline"
							outlined
						></v-text-field>
						<forgot-password />
						<v-btn
							block
							large
							outlined
							type="submit"
							color="success"
							class="border-shaped"
							:disabled="!valid"
							:loading="loading"
							@click="validate"
						>
							{{ $t('menu.login') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</section>
</template>

<script lang="js">
	import { EventBus } from '@/utils';
	import PageTitle from '@/components/info/PageTitle';
	import ForgotPassword from '../components/user/ForgotPassword';

	export default {
	    name: 'Login',
	    metaInfo: {
	        title: 'Rapidbook - Login',
	        metas: [
	            {
	                name: 'com.silverpop.brandeddomains',
	                content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
	            },
	        ],
	        script: [
	            {
	                src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
	            },
	        ],
	    },
	    components: { PageTitle, ForgotPassword },
	    props: [],
	    data() {
	        return {
	            valid: true,
	            username: '',
	            password: '',
	            loading: false,
	            errorMsg: '',
	            usernameRules: [(v) => !!v || this.$t('form.required.username')],
	            passwordRules: [(v) => !!v || this.$t('form.required.password')],
	        };
	    },
	    computed: {
	        items: function () {
	            return [
	                {
	                    text: 'Rapidbook',
	                    disabled: false,
	                    to: '/',
	                },
	                {
	                    text: this.$t('menu.login'),
	                    disabled: true,
	                    to: '',
	                },
	            ];
	        },
	    },
	    mounted() {
	        EventBus.$on('failedAuthentication', (msg) => {
	            this.errorMsg = msg;
	            this.loading = false;
	        });
	        EventBus.$on('successAuthentication', () => {
	            EventBus.$emit('refreshPanier');
	            this.$router.push({ name: 'Dashboard' }).catch(() => {});
	        });
	    },
	    beforeDestroy() {
	        EventBus.$off('failedAuthentication');
	    },
	    methods: {
	        validate() {
	            this.$refs.form.validate();
	        },
	        login(e) {
	            e.preventDefault();
	            this.loading = true;
	            this.$store.dispatch('login', { username: this.username, password: this.password }).catch(() => {});
	        },
	    },
	};
</script>

<style scoped lang="scss">
	.login {
		min-height: calc(100vh - 64px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Se Connecter"
	},
	"en": {
		"title": "Login"
	}
}
</i18n>
