<template>
	<div>
		<v-menu offset-y top rounded="lg" transition="scroll-y-reverse-transition">
			<template #activator="{ on, attrs }">
				<v-btn small fab v-bind="attrs" depressed v-on="on">
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on">mdi-comment-question-outline</v-icon>
						</template>
						<span>{{ $t('need_help') }}</span>
					</v-tooltip>
				</v-btn>
			</template>
			<v-list>
				<v-list-item link v-for="tuto in tutos" :key="tuto.id" @click="showTuto(tuto)">
					<v-list-item-icon>
						<v-icon>{{ tuto.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ tuto.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>
		<v-dialog v-model="dialog" max-width="1100px" class="border-shaped" transition="scale-transition">
			<v-card shaped elevation="24">
				<v-card-title></v-card-title>
				<v-card-text>
					<v-lazy transition="fade-transition">
						<video
							src="@/assets/tuto/Simulation tarifaire 1.mp4"
							controls
							autoplay
							onloadstart="this.volume=0.33"
							v-if="currentTuto === 1"
							style="width: 100%; height: 100%"
							class="border-shaped"
						></video>
					</v-lazy>
					<v-lazy transition="fade-transition">
						<video
							src="@/assets/tuto/Adresse expedition 2.mp4"
							controls
							autoplay
							onloadstart="this.volume=0.33"
							v-if="currentTuto === 2"
							style="max-width: 1100px; width: 100%"
							class="border-shaped"
						></video>
					</v-lazy>
					<v-lazy transition="fade-transition">
						<video
							src="@/assets/tuto/Ajout titre 3.mp4"
							controls
							autoplay
							onloadstart="this.volume=0.33"
							v-if="currentTuto === 3"
							style="max-width: 1100px; width: 100%"
							class="border-shaped"
						></video>
					</v-lazy>
					<v-lazy transition="fade-transition">
						<video
							src="@/assets/tuto/Passage commande 4.mp4"
							controls
							autoplay
							onloadstart="this.volume=0.33"
							v-if="currentTuto === 4"
							style="max-width: 1100px; width: 100%"
							class="border-shaped"
						></video>
					</v-lazy>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'Tutos',
		props: [],
		data() {
			return {
				dialog: false,
				currentTuto: null,
			};
		},
		computed: {
			tutos: function () {
				const tutos = [
					{
						id: 1,
						title: this.$t('tuto_simulate'),
						icon: 'mdi-poll',
					},
					{
						id: 2,
						title: this.$t('tuto_add_address'),
						icon: 'mdi-map-marker-outline',
					},
					{
						id: 3,
						title: this.$t('tuto_add_title'),
						icon: 'mdi-bookshelf',
					},
					{
						id: 4,
						title: this.$t('tuto_order'),
						icon: 'mdi-truck-outline',
					},
				];
				return tutos;
			},
		},
		methods: {
			showTuto(tuto) {
				this.currentTuto = tuto.id;
				this.dialog = true;
			},
		},
		watch: {
			dialog: function () {
				if (!this.dialog) {
					this.currentTuto = null;
				}
			},
		},
	};
</script>

<i18n lang="json">
{
	"fr": {
		"need_help": "Besoin d'aide ?",
		"tuto_add_address": "Ajouter une adresse ?",
		"tuto_add_title": "Ajouter un titre ?",
		"tuto_order": "Passer commande ?",
		"tuto_simulate": "Faire un devis ?"
	},
	"en": {
		"need_help": "Need help ?",
		"tuto_add_address": "Add address ?",
		"tuto_add_title": "Add title ?",
		"tuto_order": "Order ?",
		"tuto_simulate": "Simulate ?"
	}
}
</i18n>
