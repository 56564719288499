<template>
	<v-row>
		<v-dialog v-model="dialog" max-width="900px">
			<template #activator="{ on, attrs }">
				<v-btn large v-bind="attrs" elevation="0" class="mb-8 ml-3 btn-add border-shaped" v-on="on">
					<v-icon>mdi-plus</v-icon>
					{{ $t('address.add') }}
				</v-btn>
			</template>
			<v-form ref="form" v-model="valid" @submit="createAddress">
				<v-card shaped elevation="24">
					<v-card-title>
						<span class="text-h4 text-lg-h3 font-weight-bold">{{ $t('address.new_address') }}</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col>
									<v-select
										v-model="address_type"
										:label="$t('address.type')"
										outlined
										class="border-shaped"
										return-object
										:items="address_types_items"
										:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
										required
										:rules="address_typesRules"
									></v-select>
									<v-text-field
										v-model="address.contact"
										:label="$t('address.contact')"
										required
										prepend-inner-icon="mdi-account"
										:rules="contact_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-if="address_type?.id > 1"
										v-model="address.raison_sociale"
										:label="$t('address.raison_sociale')"
										:rules="raison_sociale_rules"
										prepend-inner-icon="mdi-domain"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.email"
										:label="$t('global.email')"
										required
										prepend-inner-icon="mdi-at"
										:rules="email_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.address"
										:label="$t('address.address')"
										required
										prepend-inner-icon="mdi-map-marker"
										:rules="address_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.complement"
										:label="$t('address.complement')"
										prepend-inner-icon="mdi-comment-text-outline"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.code_postal"
										:label="$t('address.postal_code')"
										required
										prepend-inner-icon="mdi-email-outline"
										:rules="code_postal_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.city"
										:label="$t('address.city')"
										required
										prepend-inner-icon="mdi-city"
										:rules="city_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-select
										v-model="address.country"
										:items="countries"
										item-text="name"
										item-value="iso"
										:label="$t('address.country')"
										return-object
										outlined
										class="border-shaped"
									>
										<template #selection="{ item }">
											<v-list-item-icon>
												<CountryFlag :country="item.iso" rounded class="mt-n2" />
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $i18n.locale === 'fr' ? item.name : item.en_name }}</v-list-item-title>
											</v-list-item-content>
										</template>
										<template #item="{ item }">
											<v-list-item-icon>
												<CountryFlag :country="item.iso" rounded class="mt-n2" />
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $i18n.locale === 'fr' ? item.name : item.en_name }}</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-select>
									<vue-tel-input-vuetify
										v-model="address.phone_mobile"
										:label="$t('address.phone.mobile')"
										:preferred-countries="['FR', 'BE', 'CH', 'CA']"
										outlined
										class="border-shaped-tel"
										mode="international"
										:rules="phone_mobile_rules"
										@validate="validPhoneMobile"
									></vue-tel-input-vuetify>
									<vue-tel-input-vuetify
										v-model="address.phone_fixe"
										:label="$t('address.phone.fix')"
										:preferred-countries="['FR', 'BE', 'CH', 'CA']"
										outlined
										class="border-shaped-tel"
										mode="international"
										:rules="phone_fixe_rules"
										@validate="validPhoneFix"
									></vue-tel-input-vuetify>
									<v-textarea v-model="address.comment" :label="$t('address.comment')" outlined counter></v-textarea>
								</v-col>
							</v-row>
						</v-container>
						<!--          <small>*indicates required field</small>-->
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="red" depressed large class="border-shaped" @click="dialog = false">
							{{ $t('global.close') }}
						</v-btn>
						<v-btn type="submit" class="border-shaped yellow--bg" depressed large :disabled="!valid" :loading="loading">
							{{ $t('form.submit') }}
						</v-btn>
					</v-card-actions>
					<v-overlay :absolute="false" :value="loading">
						<v-progress-circular indeterminate size="64"></v-progress-circular>
					</v-overlay>
				</v-card>
			</v-form>
		</v-dialog>
	</v-row>
</template>

<script lang="js">
	import axios from 'axios';
	import { EventBus } from '@/utils';

	export default {
		name: 'AddressAdd',
		props: [],
		data() {
			return {
				loading: false,
				valid: false,
				dialog: false,
				address_type: null,
				address: {
					type: { label: 'Particulier', value: 'particulier' },
					contact: '',
					raison_sociale: '',
					email: '',
					address: '',
					complement: '',
					code_postal: '',
					city: '',
					country: { name: 'France', iso: 'FR' },
					phone_mobile: '',
					phone_fixe: '',
					comment: '',
				},
				contact_rules: [(v) => !!v || this.$t('form.required.address.contact')],
				raison_sociale_rules: [(v) => !!v || this.$t('form.required.address.raison_sociale')],
				email_rules: [
					(v) => !!v || this.$t('form.required.email'),
					(v) => /.+@.+\..+/.test(v) || this.$t('form.invalid.email'),
				],
				address_rules: [(v) => !!v || this.$t('form.required.address.address')],
				code_postal_rules: [(v) => !!v || this.$t('form.required.address.postal_code')],
				city_rules: [(v) => !!v || this.$t('form.required.address.city')],
				isPhoneMobileValid: null,
				phone_mobile_rules: [
					() =>
						!!this.address.phone_mobile || !!this.address.phone_fixe || this.$t('form.required.address.phone'),
					() =>
						(!this.address.phone_mobile && !!this.address.phone_fixe) ||
						this.isPhoneMobileValid ||
						this.$t('form.invalid.address.phone.mobile'),
				],
				isPhoneFixValid: null,
				phone_fixe_rules: [
					() =>
						!!this.address.phone_mobile || !!this.address.phone_fixe || this.$t('form.required.address.phone'),
					() =>
						(!this.address.phone_fixe && !!this.address.phone_mobile) ||
						this.isPhoneFixValid ||
						this.$t('form.invalid.address.phone.fix'),
				],
				countries: [],
				address_types_items: [],
				address_typesRules: [(v) => v !== null || this.$t('form.required.address.type')],
			};
		},
		computed: {},
		mounted() {
			this.getCountries();
			this.getAddressType();
		},
		methods: {
			validPhoneMobile(value) {
				this.isPhoneMobileValid = value.isValid;
			},
			validPhoneFix(value) {
				this.isPhoneFixValid = value.isValid;
			},
			getCountries() {
				axios
					.get(process.env.VUE_APP_API_URL + '/country', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.countries = response.data.countries;
					});
			},
			getAddressType() {
				axios
					.get(process.env.VUE_APP_API_URL + '/address_type', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.address_types_items = r.data.address_types;
						!this.address_type ? (this.address_type = this.address_types_items[0]) : null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
			createAddress(e) {
				e.preventDefault();
				if (this.$refs.form.validate()) {
					this.loading = true;
					const data = {
						address: this.address.address,
						code_postal: this.address.code_postal,
						city: this.address.city,
						complement: this.address.complement,
						raison_sociale: this.address.raison_sociale,
						contact: this.address.contact,
						email: this.address.email,
						phone_mobile: this.address.phone_mobile,
						phone_fix: this.address.phone_fixe,
						address_type: this.address_type.id,
						comment: this.address.comment,
						country: this.address.country.iso,
					};
					axios
						.post(process.env.VUE_APP_API_URL + '/address', data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							this.loading = false;
							this.dialog = false;
							EventBus.$emit('refreshAddresses');
							EventBus.$emit('notif', this.$t('address.created'), 'success');
							this.$refs.form.reset();
						})
						.catch(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.btn-add {
		background-color: var(--color-yellow) !important;
	}
</style>
