<template>
	<div>
		<v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
			<template #activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed class="yellow--bg border-shaped" @click="dialog = true">
					<v-icon>mdi-cart</v-icon>
				</v-btn>
			</template>
			<v-card shaped>
				<v-card-title class="text-h5"> Ajouter au point de livraison </v-card-title>
				<v-card-subtitle class="mt-4"> Produit : {{ product.title }} </v-card-subtitle>
				<v-divider class="mb-4"></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-form ref="form" @submit.prevent="validateOrderPoint">
								<v-alert v-if="errors" type="error" class="mb-4">{{ errors }}</v-alert>
								<v-select
									class="border-shaped"
									outlined
									:items="orderPoints"
									v-model="selectedOrderPoint"
									:item-text="(item) => 'Point de livraison n°' + (orderPoints.indexOf(item) + 1)"
									return-object
								></v-select>
								<v-text-field
									class="border-shaped"
									outlined
									label="Quantité"
									v-model="quantity"
									type="number"
									:min="minQuantity"
									:max="maxQuantity"
									:rules="quantityRules"
								></v-text-field>
							</v-form>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn depressed class="yellow--bg border-shaped" @click="validateOrderPoint">
						<v-icon>mdi-check</v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { nanoid } from 'nanoid';

	export default {
		name: 'OrderProduct',
		props: {
			product: {
				type: Object,
				required: true,
			},
			orderPoints: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				errors: '',
				dialog: false,
				selectedOrderPoint: null,
				quantity: 64,
				quantityRules: [
					(v) => parseInt(v) <= this.maxQuantity || this.$t('quantity_max', [this.maxQuantity]),
					(v) => parseInt(v) >= this.minQuantity || this.$t('quantity_min', [this.minQuantity]),
				],
			};
		},
		computed: {
			minQuantity() {
				return this.product.finition?.id === 2
					? this.product.finition?.min_quantity
					: this.product.impression?.id === 1
					? Math.max(
							this.product.papier_couverture?.papier.min_exemplaires,
							this.product.papier_interieur?.papier.min_exemplaires
					  ) || this.product.finition?.min_quantity
					: Math.max(
							this.product.papier_couverture?.papier.min_exemplaires_couleur,
							this.product.papier_interieur?.papier.min_exemplaires_couleur
					  ) || this.product.finition?.min_quantity;
			},
			maxQuantity() {
				return this.product.finition?.id === 2
					? this.product.finition?.max_quantity
					: this.product.impression?.id === 1
					? Math.min(
							this.product.papier_couverture?.papier.max_exemplaires,
							this.product.papier_interieur?.papier.max_exemplaires
					  ) || this.product.finition?.max_quantity
					: Math.min(
							this.product.papier_couverture?.papier.max_exemplaires_couleur,
							this.product.papier_interieur?.papier.max_exemplaires_couleur
					  ) || this.product.finition?.max_quantity;
			},
		},
		mounted() {},
		methods: {
			validateOrderPoint() {
				this.errors = '';
				if (!this.$refs.form.validate()) return;

				const existingLineIndex = this.selectedOrderPoint.lines.findIndex((l) => l.product.uuid === this.product.uuid);

				if (existingLineIndex !== -1) {
					if (this.selectedOrderPoint.lines[existingLineIndex].quantity + this.quantity > this.maxQuantity) {
						this.errors = this.$t('quantity_max', [this.maxQuantity]);
						return;
					}
					this.selectedOrderPoint.lines[existingLineIndex].quantity += this.quantity;
					this.dialog = false;
					return;
				}

				this.selectedOrderPoint.lines.push({
					product: this.product,
					quantity: this.quantity,
					uuid: nanoid(),
				});

				this.dialog = false;
			},
		},
		watch: {
			dialog(newValue) {
				if (newValue) {
					if (this.orderPoints.length === 0) {
						const orderPointDefault = {
							uuid: nanoid(),
							lines: [],
							address: {
								address_type: null,
								country: null,
								code_postal: '',
							},
						};
						this.orderPoints.push(orderPointDefault);
					}
					this.selectedOrderPoint = this.orderPoints[0];
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>

<i18n lang="json">
{
	"fr": {
		"title": "Simulation Tarifaire",
		"unavailable": "Non disponible",
		"go_to_creation": "Passer à la création de produit",
		"choose_impression": "Choisissez un mode d'impression",
		"quantity_min": "Quantité supérieure à {0}",
		"quantity_max": "Quantité inférieure ou égal à {0}",
		"page_count_min": "Nombre de pages supérieur à 0",
		"page_count_min_pique": "Nombre de pages supérieur à 8",
		"page_count_max_pique": "Nombre de pages inférieure à 76 (76 + 4 (couverture))",
		"page_count_odd": "Nombre de pages positif ou divisible par 2",
		"page_count_by_4": "Nombre de pages positif ou divisible par 4",
		"calc_price": "Simuler",
		"width_limit": "La largeur doit être comprise entre {0} et {1} mm",
		"height_limit": "La hauteur doit être comprise entre {0} et {1} mm",
		"enable_couverture": "Activer/Désactiver la couverture",
		"papier_creation": "Pas de pelliculage pour un papier de création",
		"validate": "Valider ce produit"
	},
	"en": {
		"title": "Pricing Simulation",
		"unavailable": "Unavailable",
		"go_to_creation": "Go to product create form",
		"choose_impression": "Choose a printing mode",
		"quantity_min": "Quantity above {0}",
		"quantity_min_pique": "Quantity above 50",
		"quantity_max": "Quantity equal or less than 2000",
		"page_count_min": "Number of pages above 0",
		"page_count_min_pique": "Number of pages above 8",
		"page_count_max_pique": "Number of pages below 76 (76 + 4 (cover))",
		"page_count_odd": "Number of pages positive or divisible by 2",
		"page_count_by_4": "Number of pages positive or divisible by 4",
		"calc_price": "Simulate",
		"width_limit": "The width must be between {0} and {1} mm",
		"height_limit": "The height must be between {0} and {1} mm",
		"enable_couverture": "Enable/Disable cover",
		"papier_creation": "No pelliculage for a creation paper",
		"validate": "Validate this product"
	}
}
</i18n>
