<template>
	<v-container>
		<v-row>
			<v-col>
				<v-breadcrumbs :items="breadcrumbs" class="ml-n6">
					<template #item="{ item }">
						<v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
							{{ item.text.toUpperCase() }}
						</v-breadcrumbs-item>
					</template>
				</v-breadcrumbs>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<h1 class="display-2">
					<div v-html="titleFormat"></div>
				</h1>
				<v-img
					lazy-src="@/assets/img/element_soustitre.svg"
					src="@/assets/img/element_soustitre.svg"
					width="100"
					height="20"
					contain
					class="my-4"
				></v-img>
			</v-col>
		</v-row>
	</v-container>
</template>

<script lang="js">
	export default {
	    name: 'PageTitle',
	    props: ['title', 'breadcrumbs'],
	    computed: {
	        titleFormat() {
	            return this.title
	                .split(' ')
	                .map((word, index) => {
	                    if (index > 0) {
	                        return "<span class='yellow--text-override'>" + word + '</span>';
	                    } else {
	                        return word;
	                    }
	                })
	                .join(' ');
	        },
	    },
	};
</script>
