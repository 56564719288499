<template>
	<div>
		<v-btn class="border-shaped ml-2 yellow--bg" depressed @click="toggleDialog">
			<v-icon v-if="item">mdi-pencil</v-icon>
			<v-icon v-else>mdi-plus</v-icon>
		</v-btn>
		<v-dialog v-model="dialog" max-width="900px">
			<v-form ref="formEditPelliculage" v-model="valid" @submit.prevent="editPelliculage">
				<v-card shaped elevation="24">
					<v-card-title class="text-h4 text-lg-h3 font-weight-bold">
						{{ item ? 'Éditer Pelliculage' : 'Ajouter Pelliculage' }}
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-text-field v-model="name" label="Libellé" outlined clearable class="border-shaped"></v-text-field>
						<v-text-field v-model="en_name" label="Libellé Anglais" outlined clearable class="border-shaped"></v-text-field>
						<v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
					</v-card-text>
					<v-card-actions>
						<v-btn class="border-shaped yellow--bg ml-auto" type="submit" :loading="loading" depressed large> Valider </v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import { EventBus } from '@/utils';
	export default {
		props: ['item'],
		data() {
			return {
				dialog: false,
				valid: false,
				loading: false,
				name: this.item ? this.item.name : '',
				en_name: this.item ? this.item.en_name : '',
				enabled: this.item ? this.item.enabled : true,
			};
		},
		methods: {
			toggleDialog() {
				this.dialog = !this.dialog;
			},
			editPelliculage() {
				this.$refs.formEditPelliculage.validate();
				if (this.valid) {
					this.loading = true;
					const data = {
						name: this.name,
						en_name: this.en_name,
						enabled: this.enabled,
					};

					if (this.item) {
						axios
							.put(process.env.VUE_APP_API_URL + '/admin/pelliculages/' + this.item.id, data, {
								headers: {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
								},
							})
							.then(() => {
								EventBus.$emit('refreshpelliculages');
								this.dialog = false;
								this.loading = false;
								EventBus.$emit('notif', 'Pelliculage modifié', 'success');
							})
							.catch(() => {
								this.loading = false;
							});
					} else {
						axios
							.post(process.env.VUE_APP_API_URL + '/admin/pelliculages', data, {
								headers: {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
								},
							})
							.then(() => {
								EventBus.$emit('refreshpelliculages');
								this.dialog = false;
								this.loading = false;
								EventBus.$emit('notif', 'Pelliculage créé', 'success');
							})
							.catch(() => {
								this.loading = false;
							});
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped></style>
