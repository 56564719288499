<template>
	<section class="dashboard">
		<v-parallax dark :height="fullHeight" src="@/assets/img/tableau_de_bord/image.jpeg">
			<v-row align="center" justify="center">
				<v-col cols="0" md="4" lg="6"></v-col>
				<v-col cols="12" md="8" lg="5" xl="4" class="mr-auto">
					<v-container fill-height fluid>
						<v-row align="center" justify="center">
							<v-col v-for="(item, i) in items" :key="i" cols="6">
								<v-card elevation="0" tile height="25vh" :to="item.route" class="tile">
									<v-card-text align="center" class="pt-16">
										<v-img :src="item.image" height="8vh" class="mb-3" contain></v-img>
										{{ item.name }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-parallax>
	</section>
</template>

<script lang="js">
	export default {
	    name: 'Dashboard',
	    metaInfo: {
	        title: 'Rapidbook - Dashboard',
	        metas: [
	            {
	                name: 'com.silverpop.brandeddomains',
	                content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
	            },
	        ],
	        script: [
	            {
	                src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
	            },
	        ],
	    },
	    props: [],
	    data() {
	        return {
	            fullHeight: window.innerHeight < 960 ? window.innerHeight - 56 : window.innerHeight - 64,
	        };
	    },
	    computed: {
	        items() {
	            return [
	                {
	                    name: this.$t('menu.library'),
	                    image: require('@/assets/img/tableau_de_bord/ma_bibliotheque.svg'),
	                    route: { name: 'Library' },
	                    condition: true,
	                },
	                {
	                    name: this.$t('menu.account'),
	                    image: require('@/assets/img/tableau_de_bord/profil.svg'),
	                    route: { name: 'Account' },
	                    condition: true,
	                },
	                {
	                    name: this.$t('menu.orders'),
	                    image: require('@/assets/img/tableau_de_bord/commande_livre.svg'),
	                    route: { name: 'Orders' },
	                    condition: !this.$store.getters.isChild,
	                },
	                {
	                    name: this.$t('menu.address'),
	                    image: require('@/assets/img/tableau_de_bord/mes_adresses.svg'),
	                    route: { name: 'Address' },
	                    condition: !this.$store.getters.isChild,
	                },
	                {
	                    name: this.$t('menu.simulation'),
	                    image: require('@/assets/img/190620_picto_20x20px/devis_en_ligne.svg'),
	                    route: { name: 'Simulation' },
	                    condition: !this.$store.getters.isChild,
	                },
	                {
	                    name: this.$t('menu.contact'),
	                    image: require('@/assets/img/tableau_de_bord/contact.svg'),
	                    route: { name: 'Contact' },
	                    condition: true,
	                },
	            ].filter((item) => item.condition);
	        },
	    },
	    mounted() {},
	    created() {
	        window.addEventListener('resize', this.onResize);
	    },
	    destroyed() {
	        window.removeEventListener('resize', this.onResize);
	    },
	    methods: {
	        onResize() {
	            window.innerHeight < 960
	                ? (this.fullHeight = window.innerHeight - 56)
	                : (this.fullHeight = window.innerHeight - 64);
	        },
	    },
	};
</script>

<style scoped lang="scss">
	.tile {
		transition: background-color 0.3s;
	}

	.tile:hover {
		background-color: var(--color-yellow);
	}
</style>
