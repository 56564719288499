<template>
	<v-dialog v-model="dialog" max-width="900px">
		<template #activator="{ on, attrs }">
			<v-btn class="border-shaped ml-2 mb-2 yellow--bg" v-bind="attrs" depressed v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-form ref="formEditUser" v-model="valid" @submit.prevent="editUser">
			<v-card shaped elevation="24">
				<v-card-title class="text-h4 text-lg-h4 font-weight-bold">
					{{ $t('title') }}
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-text-field
						v-model="real_name"
						class="border-shaped"
						:label="$t('user.publishername')"
						outlined
						:rules="real_name_rules"
					></v-text-field>
					<v-text-field
						v-model="username"
						class="border-shaped"
						:label="$t('user.username')"
						outlined
						:rules="username_rules"
					></v-text-field>
					<v-text-field
						v-model="email"
						class="border-shaped"
						:label="$t('global.email')"
						outlined
						:rules="email_rules"
						type="email"
					></v-text-field>
					<v-text-field
						v-model="phone"
						class="border-shaped"
						:label="$t('global.phone')"
						outlined
						:rules="phone_rules"
					></v-text-field>
					<v-text-field
						v-model="password"
						class="border-shaped"
						:label="$t('form.password')"
						outlined
						:rules="password_rules"
						type="password"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-btn class="border-shaped yellow--bg ml-auto" type="submit" :loading="loading" large depressed>
						{{ $t('form.submit') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script lang="js">
	import axios from 'axios';
	import { EventBus } from '@/utils';

	export default {
	    name: 'UserEdit',
	    props: ['user'],
	    data() {
	        return {
	            dialog: null,
	            valid: false,
	            loading: null,
	            real_name: this.user.real_name,
	            username: this.user.username,
	            email: this.user.email,
	            phone: this.user.phone,
	            password: '',
	            real_name_rules: [() => this.real_name.trim().length > 0 || this.$t('form.required.user.publishername')],
	            username_rules: [() => this.username.trim().length > 0 || this.$t('form.required.user.username')],
	            email_rules: [() => this.email.trim().length > 0 || this.$t('form.required.email')],
	            phone_rules: [() => this.phone.trim().length > 0 || this.$t('form.required.phone')],
	            password_rules: [() => this.password.trim().length > 0 || this.$t('form.required.password')],
	        };
	    },
	    computed: {},
	    mounted() {},
	    methods: {
	        editUser() {
	            this.$refs.formEditUser.validate();
	            if (this.valid) {
	                this.loading = true;
	                const data = {
	                    real_name: this.real_name,
	                    username: this.username,
	                    email: this.email,
	                    phone: this.phone,
	                    password: this.password,
	                };
	                axios
	                    .put(process.env.VUE_APP_API_URL + '/user', data, {
	                        headers: {
	                            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                        },
	                    })
	                    .then(() => {
	                        EventBus.$emit('refreshUser');
	                        this.dialog = false;
	                        EventBus.$emit('notif', this.$t('user_edited'), 'success');
	                    })
	                    .catch((e) => {
	                        EventBus.$emit('refreshUser');
	                        try {
	                            if (e.response.data.message === 'Bad Password') {
	                                EventBus.$emit('notif', this.$t('form.invalid.password'), 'error');
	                            }
	                            if (e.response.data.message === 'Username already taken') {
	                                EventBus.$emit('notif', this.$t('username_exists'), 'error');
	                            }

	                            if (e.response.status === 429) {
	                                EventBus.$emit('notif', this.$t('global.error'), 'error');
	                            }
	                        } catch (e) {
	                            EventBus.$emit('notif', this.$t('global.error'), 'error');
	                        }
	                    })
	                    .finally(() => {
	                        this.loading = false;
	                    });
	            }
	        },
	    },
	};
</script>

<i18n lang="json">
{
	"fr": {
		"title": "Editer le compte",
		"user_edited": "Compte modifié",
		"username_exists": "Nom d'utilisateur déjà pris"
	},
	"en": {
		"title": "Edit account",
		"user_edited": "Account edited",
		"username_exists": "Username already taken"
	}
}
</i18n>
