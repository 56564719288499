<template>
	<v-row>
		<v-dialog v-model="dialog" max-width="900px">
			<template #activator="{ on, attrs }">
				<v-btn depressed v-bind="attrs" class="border-shaped ml-2 yellow--bg" v-on="on">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>
			<v-form ref="form" v-model="valid" @submit="editAddress">
				<v-card shaped elevation="24">
					<v-card-title>
						<span class="headline">Editer Adresse</span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col>
									<v-select
										v-model="address.type"
										:items="types"
										item-text="label"
										item-value="value"
										label="Type d'adresse"
										return-object
										outlined
										class="border-shaped"
									></v-select>
									<v-text-field
										v-model="address.contact"
										label="Contact"
										required
										prepend-inner-icon="mdi-account"
										:rules="contact_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-if="address.type.value !== 'particulier'"
										v-model="address.raison_sociale"
										label="Raison Sociale"
										:rules="raison_sociale_rules"
										prepend-inner-icon="mdi-domain"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.email"
										label="Email"
										required
										prepend-inner-icon="mdi-at"
										:rules="email_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.address"
										label="Adresse"
										required
										prepend-inner-icon="mdi-map-marker"
										type="email"
										:rules="address_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.complement"
										label="Complement"
										prepend-inner-icon="mdi-comment-text-outline"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.code_postal"
										label="Code Postal"
										required
										prepend-inner-icon="mdi-email-outline"
										:rules="code_postal_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-text-field
										v-model="address.city"
										label="Ville"
										required
										prepend-inner-icon="mdi-city"
										:rules="city_rules"
										outlined
										class="border-shaped"
									></v-text-field>
									<v-select
										v-model="address.country"
										:items="countries"
										item-text="name"
										item-value="iso"
										label="Pays"
										return-object
										outlined
										class="border-shaped"
									>
										<template #selection="{ item }">
											<v-list-item-icon><CountryFlag :country="item.iso" rounded class="mt-n2" /></v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ item.name }}</v-list-item-title>
											</v-list-item-content>
										</template>
										<template #item="{ item }">
											<v-list-item-icon><CountryFlag :country="item.iso" rounded class="mt-n2" /></v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ item.name }}</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-select>
									<vue-tel-input-vuetify
										v-model="address.phone_mobile"
										label="Telephone Mobile"
										:preferred-countries="['FR', 'BE', 'CH', 'CA']"
										outlined
										class="border-shaped-tel"
										mode="international"
										:rules="phone_mobile_rules"
										@validate="validPhoneMobile"
									></vue-tel-input-vuetify>
									<vue-tel-input-vuetify
										v-model="address.phone_fixe"
										label="Telephone Fixe"
										:preferred-countries="['FR', 'BE', 'CH', 'CA']"
										outlined
										class="border-shaped-tel"
										mode="international"
										:rules="phone_fixe_rules"
										@validate="validPhoneFix"
									></vue-tel-input-vuetify>
									<v-textarea v-model="address.comment" label="Commentaire" outlined counter></v-textarea>
								</v-col>
							</v-row>
						</v-container>
						<!--          <small>*indicates required field</small>-->
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn type="submit" class="border-shaped yellow--bg" depressed large :disabled="!valid" :loading="loading">
							Valider
						</v-btn>
					</v-card-actions>
					<v-overlay :absolute="false" :value="loading">
						<v-progress-circular indeterminate size="64"></v-progress-circular>
					</v-overlay>
				</v-card>
			</v-form>
		</v-dialog>
	</v-row>
</template>

<script lang="js">
	import axios from 'axios';
	import { EventBus } from '@/utils';

	export default {
	    name: 'AddressAdminEdit',
	    props: ['item'],
	    data() {
	        return {
	            loading: false,
	            valid: false,
	            dialog: false,
	            address: {
	                type: { label: this.item.address_type.name, value: this.item.address_type.name.toLowerCase() },
	                contact: this.item.contact,
	                raison_sociale: this.item.raison_sociale,
	                email: this.item.email,
	                address: this.item.address,
	                complement: this.item.complement,
	                code_postal: this.item.code_postal,
	                city: this.item.city,
	                country: this.item.country,
	                phone_mobile: this.item.phone_mobile,
	                phone_fixe: this.item.phone_fix,
	                comment: this.item.comment,
	            },
	            contact_rules: [(v) => !!v || 'Contact est requis'],
	            raison_sociale_rules: [(v) => !!v || 'Raison Sociale est requis'],
	            email_rules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || "E-mail n'est pas valide"],
	            address_rules: [(v) => !!v || 'Adresse est requis'],
	            code_postal_rules: [(v) => !!v || 'Code Postal est requis'],
	            city_rules: [(v) => !!v || 'Ville est requis'],
	            isPhoneMobileValid: null,
	            phone_mobile_rules: [
	                () =>
	                    !!this.address.phone_mobile || !!this.address.phone_fixe || this.$t('form.required.address.phone'),
	                () =>
	                    (!this.address.phone_mobile && !!this.address.phone_fixe) ||
	                    this.isPhoneMobileValid ||
	                    this.$t('form.invalid.address.phone.mobile'),
	            ],
	            isPhoneFixValid: null,
	            phone_fixe_rules: [
	                () =>
	                    !!this.address.phone_mobile || !!this.address.phone_fixe || this.$t('form.required.address.phone'),
	                () =>
	                    (!this.address.phone_fixe && !!this.address.phone_mobile) ||
	                    this.isPhoneFixValid ||
	                    this.$t('form.invalid.address.phone.fix'),
	            ],
	            countries: [],
	            types: [
	                { label: 'Particulier', value: 'particulier' },
	                { label: 'Professionelle', value: 'professionelle' },
	                { label: 'Distributeur', value: 'distributeur' },
	                { label: 'Interne', value: 'interne' },
	            ],
	        };
	    },
	    computed: {},
	    mounted() {
	        // this.getCountries()
	        this.type = this.types[Math.floor(Math.random() * this.types.length)];
	    },
	    methods: {
	        validPhoneMobile(value) {
	            this.isPhoneMobileValid = value.isValid;
	        },
	        validPhoneFix(value) {
	            this.isPhoneFixValid = value.isValid;
	        },
	        getCountries() {
	            axios
	                .get(process.env.VUE_APP_API_URL + '/country', {
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                })
	                .then((response) => {
	                    this.countries = response.data.countries;
	                });
	        },
	        editAddress(e) {
	            e.preventDefault();
	            if (this.$refs.form.validate()) {
	                this.loading = true;
	                const data = {
	                    address: this.address.address,
	                    code_postal: this.address.code_postal,
	                    city: this.address.city,
	                    complement: this.address.complement,
	                    raison_sociale: this.address.raison_sociale,
	                    contact: this.address.contact,
	                    email: this.address.email,
	                    phone_mobile: this.address.phone_mobile,
	                    phone_fix: this.address.phone_fixe,
	                    address_type: this.address.type.value,
	                    comment: this.address.comment,
	                    country: this.address.country.iso,
	                };
	                axios
	                    .put(process.env.VUE_APP_API_URL + '/admin/addresses/' + this.item.id, data, {
	                        headers: {
	                            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                        },
	                    })
	                    .then(() => {
	                        this.loading = false;
	                        this.dialog = false;
	                        EventBus.$emit('refreshaddresses');
	                        EventBus.$emit('notif', 'Adresse modifiée', 'success');
	                        this.$refs.form.reset();
	                    })
	                    .catch(() => {
	                        this.loading = false;
	                    });
	            }
	        },
	    },
	    watch: {
	        dialog: function (val) {
	            if (val) {
	                this.getCountries();
	            }
	        },
	    },
	};
</script>

<style scoped lang="scss">
	.btn-add {
		background-color: var(--color-yellow) !important;
	}
</style>
