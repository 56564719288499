<template>
	<div>
		<v-btn class="border-shaped ml-2 yellow--bg" depressed @click="toggleDialog">
			<v-icon>mdi-pencil</v-icon>
		</v-btn>
		<v-dialog v-model="dialog" max-width="900px">
			<v-form ref="formEditUser" v-model="valid" @submit.prevent="editUser">
				<v-card shaped elevation="24">
					<v-card-title class="text-h4 text-lg-h3 font-weight-bold"> Editer Utilisateur </v-card-title>
					<v-divider></v-divider>
					<v-card-text>
						<v-text-field
							v-model="code_compta"
							label="Code Compta"
							outlined
							class="border-shaped"
							:rules="notBlankRule"
						></v-text-field>
						<v-text-field
							v-model="code_masterprint"
							label="Code Masterprint"
							outlined
							class="border-shaped"
							:rules="notBlankRule"
						></v-text-field>
						<v-text-field
							v-model="real_name"
							label="Nom d'éditeur"
							outlined
							class="border-shaped"
							:rules="notBlankRule"
						></v-text-field>
						<v-text-field
							v-model="username"
							label="Nom d'utilisateur"
							outlined
							class="border-shaped"
							:rules="notBlankRule"
						></v-text-field>
						<v-text-field v-model="email" label="Email" outlined class="border-shaped" :rules="notBlankRule"></v-text-field>
						<vue-tel-input-vuetify
							v-model="phone"
							label="Téléphone"
							:preferred-countries="['FR', 'BE', 'CH', 'CA']"
							outlined
							class="border-shaped-tel"
							mode="international"
						></vue-tel-input-vuetify>
						<v-autocomplete
							v-model="user_parent_id"
							:items="user_items"
							label="Parent"
							class="border-shaped"
							outlined
							clearable
							:item-text="(user) => user.username + ' - ' + user.email"
							item-value="id"
						></v-autocomplete>
						<v-select
							v-model="role"
							:items="roles"
							item-text="name"
							:label="$t('user.role')"
							class="border-shaped"
							outlined
						></v-select>
						<v-text-field
							v-model="password"
							label="Nouveau mot de passe"
							outlined
							class="border-shaped"
							type="password"
							:rules="password_rules"
						>
							<template #label></template>
						</v-text-field>
						<v-text-field
							v-model="confirm_password"
							label="Confirmer le mot de passe"
							outlined
							class="border-shaped"
							type="password"
							:rules="confirm_password_rules"
						></v-text-field>
						<password v-model="password" :strength-meter-only="true" @feedback="showFeedback" />
						<v-list dense>
							<v-list-item v-for="(item, i) in feedback" :key="i">
								<v-list-item-icon>
									<v-icon>mdi-information</v-icon>
								</v-list-item-icon>
								{{ item }}
							</v-list-item>
						</v-list>
						<v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
					</v-card-text>
					<v-card-actions>
						<v-btn class="border-shaped yellow--bg ml-auto" type="submit" :loading="loading" depressed large> Valider </v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import { EventBus } from '@/utils';
	import Password from 'vue-password-strength-meter';

	export default {
		name: 'UsersAdminEdit',
		components: { Password },
		props: ['item'],
		data() {
			return {
				dialog: false,
				valid: false,
				loading: false,
				code_compta: this.item.code_compta,
				code_masterprint: this.item.code_masterprint,
				real_name: this.item.real_name,
				username: this.item.username,
				email: this.item.email,
				phone: this.item.phone,
				user_parent_id: this.item.user_parent_id,
				user_items: [],
				enabled: this.item.enabled,
				role: this.item.roles[this.item.roles.length - 1].id,
				roles: [
					{
						name: 'User',
						value: 1,
					},
					{
						name: 'Admin',
						value: 2,
					},
				],
				password: '',
				confirm_password: '',
				feedback: [],
				notBlankRule: [],
				password_rules: [],
				confirm_password_rules: [
					(v) => this.password === '' || !!v || 'Champs requis',
					() => this.password === this.confirm_password || 'Mot de passe différent',
				],
			};
		},
		mounted() {},
		methods: {
			toggleDialog() {
				this.dialog = !this.dialog;
				if (this.dialog) this.getUsers();
			},
			showFeedback({ suggestions }) {
				this.feedback = suggestions;
			},
			getUsers() {
				axios
					.get(process.env.VUE_APP_API_URL + '/admin/users', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.user_items = response.data.items;
					})
					.catch(() => {});
			},
			editUser() {
				this.$refs.formEditUser.validate();
				if (this.valid) {
					this.loading = true;
					const data = {
						code_compta: this.code_compta,
						code_masterprint: this.code_masterprint,
						real_name: this.real_name,
						username: this.username,
						email: this.email,
						phone: this.phone,
						user_parent_id: this.user_parent_id,
						password: this.password,
						confirm_password: this.confirm_password,
						enabled: this.enabled,
						role: this.role,
					};
					axios
						.put(process.env.VUE_APP_API_URL + `/admin/users/${this.item.id}`, data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							this.dialog = false;
							this.loading = false;
							EventBus.$emit('refreshusers');
							EventBus.$emit('notif', 'Utilisateur modifié', 'success');
						})
						.catch((e) => {
							EventBus.$emit('notif', e.response.data.message, 'error');
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<style scoped></style>
